import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import HostedLayout from './components/HostedLayout';
import Card from './pages/Card';
import Ussd from './pages/Ussd';
import BankTransfer from './pages/BankTransfer';
import PopupLayout from './components/PopupLayout';
import PageNotFound from './pages/PageNotFound';
import Home from './pages/Home';

function App() {
  return (
    <Routes>
      <Route path='/hosted/:reference_code' element={<HostedLayout />}>
        <Route path='' element={<Home />} ></Route>
        <Route path='card' element={<Card />} ></Route>
        <Route path='ussd' element={<Ussd />} ></Route>
        <Route path='bank-transfer' element={<BankTransfer />} ></Route>
      </Route>
      
      <Route path='/popup/:reference_code' element={<PopupLayout />}>
        <Route path='' element={<Home />} ></Route>
        <Route path='card' element={<Card />} ></Route>
        <Route path='ussd' element={<Ussd />} ></Route>
        <Route path='bank-transfer' element={<BankTransfer />} ></Route>
      </Route>
      
      <Route path='/404' element={<PageNotFound />} ></Route>
      
      <Route path='*' element={<Navigate to={'/404'} />} ></Route>
    </Routes>
  );
}

export default App;
