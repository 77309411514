
import React from 'react'
import { useApp } from '@/contexts/appContext';
import classNames from 'classnames';
import { FaSlackHash } from 'react-icons/fa';
import { FaCreditCard } from 'react-icons/fa6';
import { GrTransaction } from 'react-icons/gr';
import { Link, useLocation } from 'react-router-dom'

function MobileMenu() {
    const { pathname } = useLocation();

    const { info } = useApp();

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    // console.log(getPath())

    return (
        <>
            <div className='sm: h-full sm:pt-36 w-full  md:mt-10'>
                <div className='px-10 py-3 text-xs mb-2'>
                    <div className='font-bold border-b-gray-800 border-b-4 inline-block'>PAYMENT METHODS</div>
                </div>
                {
                    info && JSON.parse(info.payment_method.allowed).includes("card") &&
                    <Link to={`/${getPath(1)}/${info?.order_reference_code}/card`} className={classNames('px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-start border-b-2 border-b-neutral-300', {
                        'bg-gray-200': getPath() === "card",
                        'bg-neutral-100': getPath() !== "card",
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                'bg-gray-900': getPath() === "card",
                                'bg-neutral-300': getPath() !== "card",
                            })}>
                                <FaCreditCard className={classNames({
                                    'text-neutral-100': getPath() === "card",
                                    'text-neutral-900': getPath() !== "card",
                                })} />
                            </div>
                            <div className={classNames({
                                'text-gray-900': getPath() === "card",
                                'text-neutral-700': getPath() !== "card",
                            })}>Pay with Card</div>
                        </div>
                    </Link>}

                {
                    info && JSON.parse(info.payment_method.allowed).includes("dynamic_virtual_account") &&
                    <Link to={`/${getPath(1)}/${info?.order_reference_code}/bank-transfer`} className={classNames('px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-start border-b-2 border-b-neutral-300', {
                        'bg-gray-200': getPath() === "bank-transfer",
                        'bg-neutral-100': getPath() !== "bank-transfer",
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                'bg-gray-900': getPath() === "bank-transfer",
                                'bg-neutral-300': getPath() !== "bank-transfer",
                            })}>
                                <GrTransaction className={classNames({
                                    'text-neutral-100': getPath() === "bank-transfer",
                                    'text-neutral-900': getPath() !== "bank-transfer",
                                })} />
                            </div>
                            <div className={classNames({
                                'text-gray-900': getPath() === "bank-transfer",
                                'text-neutral-700': getPath() !== "bank-transfer",
                            })}>Pay with Bank Transfer</div>
                        </div>
                    </Link>}

                {
                    info && JSON.parse(info.payment_method.allowed).includes("ussd") &&
                    <Link to={`/${getPath(1)}/${info?.order_reference_code}/ussd`} className={classNames('px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-start border-b-2 border-b-neutral-300', {
                        'hidden': getPath() === "ussd",
                        'bg-neutral-100': getPath() !== "ussd",
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                'bg-gray-900': getPath() === "ussd",
                                'bg-neutral-300': getPath() !== "ussd",
                            })}>
                                <FaSlackHash className={classNames({
                                    'text-neutral-100': getPath() === "ussd",
                                    'text-neutral-900': getPath() !== "ussd",
                                })} />
                            </div>
                            <div className={classNames({
                                'text-gray-900': getPath() === "ussd",
                                'text-neutral-700': getPath() !== "ussd",
                            })}>Pay with USSD</div>
                        </div>
                    </Link>}
            </div>
        </>
    )
}

export default MobileMenu