"use client"

import { FaCreditCard } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";
import { FaSlackHash } from "react-icons/fa";
import classNames from "classnames";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApp } from "@/contexts/appContext";
import { useEffect } from "react";
import Loader from "./Loader";
import StatusModal from "./StatusModal";
import { IPaymentType } from "@/types/global.types";
import http from "@/lib/http";
import { numberFormat } from "@/utils/helpers";

export default function HostedLayout() {

    const params = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { info, isFetching, setIsFetching, setInfo } = useApp();

    useEffect(() => {
        if (!isFetching && (!info || !info.order_reference_code)) {
            navigate('/404', { replace: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, info]);

    async function getInfo() {
        setIsFetching(true);
        try {
            const resp = await http("wallet/collection/info", { order_reference_code: params.reference_code });
            setInfo(resp.data);
            console.log(resp.data)
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        getInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    return (
        <div className="w-full font-container">
            {!isFetching && info && info.order_reference_code ?
                info.payment_status === "Pending" ?
                    <div
                        className="flex h-lvh justify-center items-center bg-gray-200 bg-no-repeat bg-cover px-3 pt-[0px]"
                    // style={{ backgroundImage: "url('/pattern.jpg')" }}
                    >

                        <div className="min-h-[600px] h-auto rounded-2xl shadow-2xl w-full md:w-[80%] lg:w-[1000px] max-h-full overflow-auto">
                            <div className='flex gap-2 lg:gap-5 min-h-[inherit] h-auto bg-white w-full'>
                                {getPath(3) && <div className='md:min-w-[210px] md:w-[30%] lg:min-w-[300px] lg:w-[30%] w-[70px] bg-neutral-50'>
                                    {
                                        /* <div className='flex justify-end p-10'>
                                            <Image src={'https://moipayway.com/wp-content/uploads/2023/04/moipayway.png'} className='w-[120px]' alt='logo' width={100} height={30} />
                                        </div> */
                                    }
                                    <div className='flex sm:h-full pt-10 sm:pt-36 flex-col items-center md:items-end w-full'>
                                        {/* <div className='px-10 py-3 text-xs mb-2'>
                                            <div className='font-bold border-b-gray-800 border-b-4'>PAYMENT METHODS</div>
                                        </div> */}
                                        {
                                            info && JSON.parse(info.payment_method.allowed).includes("card") &&
                                            <Link to={`/hosted/${info?.order_reference_code}/card`} className={classNames('px-1 md:px-5 lg:px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-center md:items-end', {
                                                'bg-gray-200 sm:bg-gray-200 text-gray-900': getPath() === "card",
                                                'bg-neutral-50 text-neutral-700 text': getPath() !== "card",
                                            })}>
                                                <div className='md:flex items-center gap-2'>
                                                    <div>Card</div>
                                                    <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                                        'bg-gray-900': getPath() === "card",
                                                        'bg-neutral-300': getPath() !== "card",
                                                    })}>
                                                        <FaCreditCard className={classNames({
                                                            'text-neutral-100': getPath() === "card",
                                                            'text-neutral-900': getPath() !== "card",
                                                        })} />
                                                    </div>
                                                </div>
                                            </Link>}

                                        {
                                            info && JSON.parse(info.payment_method.allowed).includes("dynamic_virtual_account") &&
                                            <Link to={`/hosted/${info?.order_reference_code}/bank-transfer`} className={classNames('px-1 md:px-5 lg:px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-center md:items-end', {
                                                'bg-gray-200 sm:bg-gray-200 text-gray-900': getPath() === "bank-transfer",
                                                'bg-neutral-50 text-neutral-700 text': getPath() !== "bank-transfer",
                                            })}>
                                                <div className='md:flex items-center gap-2'>
                                                    <div className="inline-block md:hidden">Bank</div>
                                                    <div className="md:inline-block hidden">Bank Transfer</div>
                                                    <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                                        'bg-gray-900': getPath() === "bank-transfer",
                                                        'bg-neutral-300': getPath() !== "bank-transfer",
                                                    })}>
                                                        <GrTransaction className={classNames({
                                                            'text-neutral-100': getPath() === "bank-transfer",
                                                            'text-neutral-900': getPath() !== "bank-transfer",
                                                        })} />
                                                    </div>
                                                </div>
                                            </Link>}

                                        {
                                            info && JSON.parse(info.payment_method.allowed).includes("ussd") &&
                                            <Link to={`/hosted/${info?.order_reference_code}/ussd`} className={classNames('px-1 md:px-5 lg:px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-center md:items-end', {
                                                'bg-gray-200 sm:bg-gray-200 text-gray-900': getPath() === "ussd",
                                                'bg-neutral-50 text-neutral-700 text': getPath() !== "ussd",
                                            })}>
                                                <div className='md:flex items-center gap-2'>
                                                    <div>USSD</div>
                                                    <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                                        'bg-gray-900': getPath() === "ussd",
                                                        'bg-neutral-300': getPath() !== "ussd",
                                                    })}>
                                                        <FaSlackHash className={classNames({
                                                            'text-neutral-100': getPath() === "ussd",
                                                            'text-neutral-900': getPath() !== "ussd",
                                                        })} />
                                                    </div>
                                                </div>
                                            </Link>}
                                    </div>
                                </div>}
                                <div className='py-5 md:py-10 pl-0 md:pr-2 lg:pr-10 lg:pl-10 flex justify-center flex-1 max-h-auto overflow-auto'>
                                    <div className='w-full md:w-[600px] pr-2 md:pr-0'>

                                        {/* Header */}
                                        <div className='mb-5 md:mb-10'>
                                            <div className='flex justify-between items-center bg-inherit border-b-2 md:border-b-0 md:p-0 p-1'>
                                                <div className='p-4 rounded-full'>
                                                    {info.merchant.logo.length > 0 && <img src={info.merchant.logo} className='w-8' alt='icon' />}
                                                </div>
                                                <div className='flex flex-col items-end pr-2'>
                                                    <div className='text-[11px] md:text-xs font-normal text-right'>{info.merchant.name}</div>
                                                    <div className='text-[12px] md:text-sm font-normal'>{info.user.email}</div>
                                                    <div className='text-[13px] md:text-lg font-bold'>{info.currency.toUpperCase()} {numberFormat(info ? info.amount : "")}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Body */}
                                        <Outlet />
                                        <div className="py-0 md:py-10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <StatusModal info={info} type={getPath(1) as IPaymentType} />
                :
                <Loader />
            }
        </div>
    );
}
