import React, { useRef, useState, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaTimesCircle } from 'react-icons/fa';

interface IframePopupProps {
    data: {
        ACSUrl: string;
        TermUrl: string;
        JWT: string;
        md: string;
    };
    setIsUserBankAuthorizePaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function IframePopup({
    data,
    setIsUserBankAuthorizePaymentModalOpen,
    setIsLoading,
}: IframePopupProps) {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const doc = iframe.contentDocument || iframe.contentWindow?.document;

            if (doc) {
                doc.open(); // Clear previous content
                doc.write(buildFormHTML(data));
                doc.close();

                const form = doc.getElementById('Form') as HTMLFormElement;
                if (form) {
                    form.submit();
                    setIsLoadingIframe(false); // Stop loading after form submission
                }
            }
        }
    }, [data]);

    const buildFormHTML = (data: IframePopupProps['data']) => {
        return `
            <form id="Form" action="${data.ACSUrl}" method="post">
                <input type="hidden" name="TermUrl" value="${data.TermUrl}">
                <input type="hidden" name="MD" value="${data.md}">
                <input type="hidden" name="JWT" value="${data.JWT}">
            </form>
        `;
    };

    function handleClose() {
        setIsUserBankAuthorizePaymentModalOpen(false);
        setIsLoading(false);
    }

    return (
        <div className='fixed top-0 left-0 w-full bg-neutral-900 bg-opacity-85 h-screen flex justify-center md:pt-[120px] z-30'>
            <div className='flex flex-col items-center w-full md:w-[500px]'>
                <div className='flex items-center justify-end w-full mb-4'>
                    <button onClick={handleClose} className='flex items-center gap-2 text-white'>
                        <FaTimesCircle />
                        <div>Close</div>
                    </button>
                </div>

                {/* Show loading indicator */}
                {isLoadingIframe && (
                    <div className='w-full h-[300px] flex items-center justify-center'>
                        <div>
                            <center>
                                <AiOutlineLoading3Quarters className='w-8 h-8 text-gray-600 animate-spin mb-10' />
                                <div className='animate-ping text-[10px] text-white font-bold'>
                                    Please wait
                                </div>
                            </center>
                        </div>
                    </div>
                )}

                {/* Always render iframe, but hide it while loading */}
                <iframe
                    src="about:blank"
                    id="PartnerPaymentIframe"
                    title='Card embed payment partner'
                    className={`w-full h-[500px] border-none ${isLoadingIframe ? 'hidden' : 'block'}`} // Hide while loading
                    ref={iframeRef}
                />
            </div>
        </div>
    );
}

export default IframePopup;
