import kbpgp from "kbpgp";
import { BadRequestError } from "./errors";
import { IPaymentInfo } from "@/types/global.types";
import { checkoutOrigin } from "./constants";

export function upperCaseFirst(input: string): string {
  if (input.length === 0) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function numberFormat(data: string) {
  return (Number(data)).toLocaleString("en-US");
}

export function currencyFormat(data: string, currency = "NGN") {
  if (data.length === 0) {
    return "NGN 0.00"
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(parseFloat(data))
}

export function formatDate(dateString: string) {
  let dateObject = new Date(dateString);
  let date = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + dateObject.getDate();
  let time = dateObject.getHours() + ":" + dateObject.getMinutes() + ":" + dateObject.getSeconds();
  let dateTime = date + ' ' + time;
  return dateTime;
}

export function shortenWord(word: string, maxLength: number) {
  if (word.length <= maxLength) {
    return word;
  }

  const shortenedWord = word.substring(0, maxLength - 3) + '...';
  return shortenedWord;
}

export function truncate(str: string, length = 25) {
  if (str.length < 1) return
  let truncatedString = str
  if (length > 10 && str.length > length) {
    truncatedString = str.substring(0, length - 3) + ' ...'
  }
  return truncatedString
}

export function formatDateNoTimezone(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  const d = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  console.log(d)
  return d;
}

export function calculateExpiryTime(duration: number, durationUnit: 'seconds' | 'minutes' | 'hours'): string {
  const currentDate = new Date();
  let expiryDate = new Date(currentDate);

  switch (durationUnit) {
    case 'seconds':
      expiryDate.setSeconds(expiryDate.getSeconds() + duration);
      break;
    case 'minutes':
      expiryDate.setMinutes(expiryDate.getMinutes() + duration);
      break;
    case 'hours':
      expiryDate.setHours(expiryDate.getHours() + duration);
      break;
    default:
      throw new Error('Invalid duration unit');
  }

  const hours = expiryDate.getHours();
  const minutes = expiryDate.getMinutes();

  // Check if expiry time exceeds 11:59 PM of today's date
  const endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
  if (expiryDate > endOfDay) {
    // Adjust expiry time to tomorrow
    expiryDate.setDate(expiryDate.getDate() + 1);
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes}PM (tomorrow)`;
  } else {
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes}PM (today)`;
  }
}

export async function encryptMessage(msg: string, key: any) {
  try {
    const resp = new Promise((resolve, reject) => {
      const params = {
        msg: msg,
        encrypt_for: key
      };

      kbpgp.box(params, (err: any, result_string: any, result_buffer: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(result_string);
        }
      });
    });

    return (await resp) as string;
  } catch (error) {
    throw new BadRequestError((error as any).message);
  }
}

export function importPublicKey(armoredKey: string) {
  return new Promise((resolve, reject) => {
      kbpgp.KeyManager.import_from_armored_pgp({
          armored: armoredKey
      }, (err:any, alice:any) => {
          if (err) {
              reject(err);
          } else {
              resolve(alice);
          }
      });
  });
}

export function getCardType(cardNumber: string): 'unknown' | 'visa' | 'verve' | 'mastercard' {
  
  const cleanNumber = cardNumber.replace(/\D/g, '');  
  if (cleanNumber.length < 2) {
    return 'unknown';
  }

  const firstDigit = cleanNumber.charAt(0);
  const firstTwoDigits = cleanNumber.substring(0, 2);
  const firstSixDigits = cleanNumber.substring(0, 6);

  // Visa
  if (firstDigit === '4') {
    return 'visa';
  }

  // Mastercard
  if (['51', '52', '53', '54', '55'].includes(firstTwoDigits) || 
      (parseInt(firstTwoDigits) >= 22 && parseInt(firstTwoDigits) <= 27)) {
    return 'mastercard';
  }

  // Verve
  // Check first 6 digits for Verve
  const verveRanges = [
    { start: 506099, end: 506198 },
    { start: 650002, end: 650027 },
    { start: 507865, end: 507964 }
  ];
  
  if (cleanNumber.length >= 6) {
    const numericSixDigits = parseInt(firstSixDigits);
    if (verveRanges.some(range => numericSixDigits >= range.start && numericSixDigits <= range.end)) {
      return 'verve';
    }
  }

  return 'unknown';
}

export function postMessage(info?:IPaymentInfo | null) {
  if (info) {
      if (info.payment_status === "Pending") {
          window.parent.postMessage({ type: "closeMPWCheckoutModal", data: {} }, checkoutOrigin);
      } else if (info.payment_status === "Successful") {
          window.parent.postMessage({ type: "MPWCheckoutSuccess", data: { data: info, error: {} } }, checkoutOrigin);
      } else {
          window.parent.postMessage({ type: "MPWCheckoutFailed", data: { data: info, error: {} } }, checkoutOrigin);
      }
  } else {
      window.parent.postMessage({ type: "closeMPWCheckoutModal", data: {} }, checkoutOrigin);
  }
}