import CountdownTimer from '@/components/CountdownTimer';
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { numberFormat } from '@/utils/helpers';
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa6';
import { GiPadlock } from "react-icons/gi";

function Ussd() {

    const { info, setInfo } = useApp();
    const toast = useToast();
    const [institutions, setInstitutions] = useState<{
        institution: string
    }[]>([]);

    const [isFetchingInstitutions, setIsFetchingInstitutions] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [tab, setTab] = useState<"select" | "pay">("select");

    const [institutionInput, setInstitutionInput] = useState("");
    const [ussdPaymentInfo, setUssdPaymentInfo] = useState<{
        code: string,
        order_reference_code: string,
        meta: {
          ussd: string,
          bank_name: string,
          duration: 300,
          duration_unit: string,
          narration: string
        }
      } | null>(null)

    async function fetchInstitutions() {
        try {
            const resp = await http("wallet/collection/method/ussd/supported-institutions", { meta: { currency_code: "ngn" } }, process.env.REACT_APP_PUBLIC_KEY);
            setInstitutions(resp.data ? resp.data : []);
            setIsFetchingInstitutions(false);
        } catch (error) {
            console.log(error);
            handleResponseError(error, toast);
            setIsFetchingInstitutions(false);
        }
    }

    function checkStatus(stopAfter: number = 20) {
        if (stopAfter <= 0) {
            console.log("done");
            return;
        }
        setTimeout(async () => {
            try {
                const resp = await http("wallet/collection/info", { order_reference_code: info?.order_reference_code });
                setInfo((e) => e !== null ? ({ ...e, payment_status: resp.data.payment_status }) : null);
                if (resp.data.payment_status === "Pending") {
                    checkStatus(stopAfter - 1);
                }
            } catch (error) {
                console.log(error);
                handleResponseError(error, toast);
                checkStatus(stopAfter - 1);
            }
        }, 5000);
    }

    useEffect(() => {
        fetchInstitutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handlePayWithUssd() {
        setIsLoading(true)
        try {
            const resp = await http("wallet/collection/method/ussd/create", { order_reference_code: info?.order_reference_code, meta: { institution: institutionInput } }, process.env.REACT_APP_PUBLIC_KEY);
            resp.data && setUssdPaymentInfo(resp.data);
            setTab("pay")
            checkStatus();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>

            {
                tab === "select" ?
                    <div>
                        <div className='w-full flex justify-center text-xs md:text-sm mb-4 md:mb-8 mt-10 md:mt-0'>
                            <div className='border-b-2 border-b-gray-900 p-1'><center>Select your bank to begin payment</center></div>
                        </div>

                        <div className='w-full flex justify-center mb-[100px]'>
                            <div className='w-full md:w-[70%]  lg:w-[60%]'>
                                <center>
                                    <div className='w-[80%] md:w-full bg-gray-50 p-2 mb-5 border-2 border-gray-100'>
                                        <div className='text-xs font-bold text-neutral-800 text-opacity-85 pl-2 text-left flex gap-2 items-center'>SELECT BANK {isFetchingInstitutions && <FaSpinner className='animate-spin' />}</div>
                                        <select value={institutionInput} onChange={(e) => setInstitutionInput(e.target.value)} className='outline-none w-full p-2 bg-transparent text-neutral-900 text-opacity-50 text-sm md:text-[15px]' >
                                            <option value="">Click to select</option>
                                            {
                                                institutions.map((e, i) => (
                                                    <option value={e.institution} key={i}>{e.institution}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </center>

                                <div className='mb-10'>
                                    <center>
                                        <button type="button" onClick={() => handlePayWithUssd()} className='w-[80%] md:w-full bg-gray-900 disabled:bg-gray-900/70 text-neutral-50 rounded-md p-3 text-sm font-bold flex justify-center' disabled={isLoading || isFetchingInstitutions || institutionInput.length === 0}>
                                            {
                                                !isLoading ?
                                                    <div>Pay {info?.currency.toUpperCase()} {numberFormat(info!.amount)}</div> :
                                                    <div className='py-1'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                            }
                                        </button>
                                    </center>
                                </div>

                                <div className='flex justify-center'>
                                    <div className='flex justify-between gap-3'>
                                        <GiPadlock className='w-4 h-4' />
                                        <div className='text-xs'>Secured by <b>MoiPayWay</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div>
                        <div className='w-full flex justify-center text-xs md:text-sm mb-4 md:mb-8 mt-10 md:mt-0'>
                            <div className='border-b-2 border-b-gray-900 p-1 font-bold'>
                                <center>Dial USSD code below to pay</center>
                            </div>
                        </div>

                        <div className='w-full flex justify-center mb-[100px]'>
                            <div className='w-full md:w-[70%]  lg:w-[60%] pt-5'>
                                <center>
                                    <div className='font-extrabold text-xl md:text-2xl mb-3'>{ussdPaymentInfo?.meta.ussd}</div>

                                    <div className='mb-4'>
                                        <div className='text-sm font-light'>Bank</div>
                                        <div className='text-sm font-bold'>{ussdPaymentInfo?.meta.bank_name}</div>
                                    </div>

                                    <div className='mb-2'>
                                        {/* <div className='text-sm font-light'>Expires</div> */}
                                        <div className='w-full flex justify-center'>
                                        <CountdownTimer duration={ussdPaymentInfo!.meta.duration} durationUnit={ussdPaymentInfo!.meta.duration_unit as any} />
                                        </div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Ussd